import React from "react";

import Layout from "../../layout/Layout";
import StyledLink from "../../components/styled-link/StyledLink";
import KontaktImage from "./assets/KontaktImage";

import kontaktStyle from "./assets/Kontakt.module.scss";

const KontaktPage = () => {
  return (
    <Layout title="Kontakt">
      <section name="contact" className={kontaktStyle["contact"]}>
        <div className={kontaktStyle["contact_container"]}>
          <div className={kontaktStyle["contact_title"]}>
            <h1>Kontakt</h1>
          </div>
          <div className={kontaktStyle["contact_content"]}>
            <div className={kontaktStyle["contact_list"]}>
              <div className={kontaktStyle["contact_list_item"]}>
                <div className={kontaktStyle["contact_list_item_title"]}>
                  <h2>Salon</h2>
                </div>
                <div className={kontaktStyle["contact_list_item_description"]}>
                  <p>Radniční 1, Most, 434 01</p>
                  <p>Pondělí - pátek: 10 - 17, sobota: 9 - 12</p>
                  <p>+420 476 102 617</p>
                </div>
              </div>
              <div className={kontaktStyle["contact_list_item"]}>
                <div className={kontaktStyle["contact_list_item_title"]}>
                  {/* */}
                </div>
                <div className={kontaktStyle["contact_list_item_description"]}>
                  <p>
                    <StyledLink href="mailto:vyoralova@svatby-most.cz">
                      Email
                    </StyledLink>
                  </p>
                  <p>
                    <StyledLink
                      href="https://www.facebook.com/SvatebnisalonDelta/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </StyledLink>
                  </p>
                  <p>
                    <StyledLink
                      href="https://www.instagram.com/svatebnisalondelta.official/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </StyledLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={kontaktStyle["contact_image"]}>
            <KontaktImage />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default KontaktPage;
